<template>
  <FormTemplate
      :header="header"
      :text="subHeader"
      button
  >
    <GivingForm/>
  </FormTemplate>
</template>

<script>
import FormTemplate from "@/components/FormTemplate";
import GivingForm from "@/components/campaign/GivingForm";
import {mapStores} from "pinia";
import {useDesignStore} from "@/stores/designStore";
import {useCmsStore} from "@/stores/cmsStore";

export default {
  name: "Giving Page",
  components: {
    FormTemplate,
    GivingForm,
  },
  data() {
    return {
      cms: {
        loaded: false, // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: ["form_template", "giving_form"],
          LanguageCode: "",
          PageName: "giving_page",
          MinistryUpdates: false,
        },
        page: {},
      },
      header: "Give now",
      subHeader:
          "Make a Difference",
    };
  },
  computed: {
    ...mapStores(useCmsStore, useDesignStore),
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore
            .getPageAndOrCommonAndOrComponents(this.cms.options)
            .then((results) => {
              if (results) {
                this.cms.page = results;
              } else return false;

              this.setAllCmsContent(results);
              this.setContentLoaded(true);
              return true;
            });
      } catch (e) {
        console.error(e);
      }
    },

    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },
    setAllCmsComponentContent(componentContents) {
      this.cms.comp = componentContents;
    },
    setAllCmsContent(cmsContent) {
      this.cms.page = cmsContent;
      this.setHeaderFromCms(cmsContent);
    },
    setHeaderFromCms(page) {
      this.header = page.fgGivenow;
      this.subHeader = page.fgYourGiftToday;
    },
  },
  async created() {
    this.setContentLoaded(false);
    await this.fetchCmsData();
  },
};
</script>

<style lang="scss" scoped>

</style>
